
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Lead.css';

 const Lead = () => {
  const [owners, setOwners] = useState([]);
   useEffect(() => {
    const fetchOwners = async () => {
      const response = await axios.get('https://polygon-mainnet.g.alchemy.com/nft/v3/zc_dnJNPbnHcDv3Y3-n_evjeuT3cFHt9/getOwnersForContract?contractAddress=0x0eEC2af6BD392841f8663AC3554C872589Bb61aA&withTokenBalances=true');
        const response2 = await axios.get('https://eth-mainnet.g.alchemy.com/nft/v3/zc_dnJNPbnHcDv3Y3-n_evjeuT3cFHt9/getOwnersForContract?contractAddress=0x427cE6c9E2a504aEB22dc3839FbC4f4B6ebD75bb&withTokenBalances=true');
        
      try {
         const ownersWithCount = response.data.owners.map((owner) => {
          const ownerInSecondCollection = response2.data.owners.find(o => o.ownerAddress === owner.ownerAddress);
          let image = ownerInSecondCollection && ownerInSecondCollection.tokenBalances[0]
            ? `https://bafybeico63pzta6k3ccmjl76jsnf2muk7pwoqu6bq3ayhrbioh7v447w2a.ipfs.dweb.link/${ownerInSecondCollection.tokenBalances[0].tokenId}.png`
            : 'https://images.squarespace-cdn.com/content/62ecbe1beea95d73d86b86ae/df8d9cb9-d139-4b10-b49d-4842064053be/default.png?content-type=image%2Fpng';
           return {
            ownerAddress: owner.ownerAddress,
            count: parseInt(owner.tokenBalances[0]?.balance || 0, 10),
            image,
            tokenId: owner.tokenBalances[0]?.tokenId
          };
        });
         ownersWithCount.sort((a, b) => b.count - a.count);
        setOwners(ownersWithCount);
      } catch (error) {
        console.error(error);
      }
    };
     fetchOwners();
  }, []);

  const categorizeAndSortOwners = (owners) => {
    const category1 = owners.filter(owner => owner.count >= 1 && owner.count < 3);
    const category2 = owners.filter(owner => owner.count >= 3 && owner.count < 5);
    const category3 = owners.filter(owner => owner.count >= 5 && owner.count < 7);
    const category4 = owners.filter(owner => owner.count >= 7 && owner.count < 10);
    const category5 = owners.filter(owner => owner.count >= 10 && owner.count < 13);
    const category6 = owners.filter(owner => owner.count >= 13 && owner.count < 18);
    const category7 = owners.filter(owner => owner.count >= 18);

    // Sort owners within each category from highest to lowest count
    category1.sort((a, b) => b.count - a.count);
    category2.sort((a, b) => b.count - a.count);
    category3.sort((a, b) => b.count - a.count);
    category4.sort((a, b) => b.count - a.count);
    category5.sort((a, b) => b.count - a.count);
    category6.sort((a, b) => b.count - a.count);
    category7.sort((a, b) => b.count - a.count);

    return [category7, category6, category5, category4, category3, category2, category1];
  }

  const [category7, category6, category5, category4, category3, category2, category1] = categorizeAndSortOwners(owners);

  return (
    
    <div className="lead-container">
      <h5 style={{ textAlign: 'right' }}><a href="https://twitter.com/Borderless_DeFi" target="_blank" rel="noreferrer">Customize for you?</a></h5>
      <center>
        <h1>Blue Sea</h1>
        <h4><a href="https://opensea.io/assets/matic/0x0eec2af6bd392841f8663ac3554c872589bb61aa/777" target="_blank" rel="noreferrer">Dive into the depths of the Mega Cards</a></h4>
    </center>
      
      <h2>Whales</h2>
      <div className="grid-container">
        {category7.map(renderOwner)}
      </div>
      <h2>Sharks</h2>
      <div className="grid-container">
        {category6.map(renderOwner)}
      </div>
      <h2>Dolphins</h2>
      <div className="grid-container">
        {category5.map(renderOwner)}
      </div>
      <h2>Stingrays</h2>
      <div className="grid-container">
        {category4.map(renderOwner)}
      </div>
      <h2>Seahorses</h2>
      <div className="grid-container">
        {category3.map(renderOwner)}
      </div>
      <h2>Turtles</h2>
      <div className="grid-container">
        {category2.map(renderOwner)}
      </div>
      <h2>Minnows</h2>
    <div className="grid-container">
      {category1.map(renderOwner)}
    </div>
    {/* Credits */}
    <center><div className="credits">
      Made with <span role="img" aria-label="heart">♡</span> by <a href="https://twitter.com/Borderless_DeFi" target="_blank" rel="noreferrer">Borderless</a>
    </div></center>
  </div>
);
};

const renderOwner = (owner) => (
  <div key={owner.ownerAddress} className="grid-item">
    <div className="owner-info">
      <p>
        <a href={`https://opensea.io/${owner.ownerAddress}`} target="_blank" rel="noreferrer">
          {`${owner.ownerAddress.slice(0, 6)}...${owner.ownerAddress.slice(-4)}`}
        </a>
        <a href={`https://debank.com/profile/${owner.ownerAddress}`} target="_blank" rel="noreferrer" style={{ marginLeft: '10px' }}>
          👁️
        </a>
      </p>
    </div>
    <div className="owner-image">
      <center>
        <img
          src={owner.image}
          onError={(event) => event.target.src = 'https://images.squarespace-cdn.com/content/62ecbe1beea95d73d86b86ae/df8d9cb9-d139-4b10-b49d-4842064053be/default.png'}
          width="175"
          height="175"
          alt="BlueNFT"
        />
      </center>
    </div>
    <p>Megacards: {owner.count}</p>
  </div>
)

export default Lead;